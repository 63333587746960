import { h } from './h';
import { getIcon } from './icons';
import { groupBySummary } from './groupBySummary';
import { fetchForecast, IForecast } from './forecast';
import { currentPosition } from './currentPosition';
import { params } from './params';
import { updateAtFormat } from './time';
import { AlertView } from './AlertView';
import { RadarView } from './RadarView';

export function AppView(root: Element) {
  async function fetchForecastForLocation() {
    const {zip,lat,lon} = params();
    let codes: Record<string,string[]> = {};

    if (zip) {
      try {
        const res = await fetch('zipcodes.json');
        codes = await res.json();
      } catch (e) {
        throw new Error(`failed to load zipcodes: ${e}`);
      }

      const coords = codes[zip];
      if (!coords) {
        throw new Error(`unknown zipcode: ${zip}`);
      }

      return await fetchForecast({
        coords: {
          latitude: coords[0],
          longitude: coords[1]
        }
      });
    }

    if (lat && lon) {
      return await fetchForecast({
        coords: {
          latitude: lat,
          longitude: lon,
        }
      });
    }

    const pos = await currentPosition();
    return await fetchForecast(pos);
  }

  async function reload() {
    const data = await fetchForecastForLocation();
    const view = h('section', { className: 'forecast' });

    view.appendChild(Title(data));
    view.appendChild(GeneratedAt(data));
    view.appendChild(AlertView(data));
    view.appendChild(RadarView(data));
    view.appendChild(DailySummary(data));

    if (root.childNodes.length > 0) {
      root.childNodes[0].replaceWith(view);
    } else {
      root.appendChild(view);
    }
  }

  return {
    reload,
  }
}

function Title(forecast) {
  const { city, state } = forecast.relativeLocation;
  let place = `${city}, ${state}`;
  const { zip } = params();
  if (zip) {
    place = `${place} (${zip})`
  }

  const {
    generatedAt,
    // periods,
  } = forecast.current;

  const subscribe = h('button', {
    href: '#notifications',
    className: 'fr',
    onClick() {
      location.reload()
    },
  }, 'Reload ↺');

  return h('div', {className:''},
    subscribe,
    h('h2', {}, place),
    h('div', {}, `${updateAtFormat(generatedAt)}`),
  )
}

function DailySummary(forecast: IForecast) {
  const grouped = groupBySummary(forecast);

  const list = h('div', { className: 'forecast--list' });
  grouped.forEach(({ period, high, low, hours, max }, idx) => {
    const additionalAttrs = idx === 0 ? { open: true } : {};

    const offset = (max - high) * 0.8;
    const width = ((high - low) || 1) * 0.8;

    const group = h('details', { className: 'forecast--list_item', ...additionalAttrs },
      h('summary', { className: 'summary'},
        h('div', { className: 'summary--row' },
          h('div', { className: 'summary--icon' }, getIcon(period.shortForecast)),
          h('h3', { className: 'summary--title' }, period.name),
          h('div', { className: 'summary--temp-bar-container' },
           h('div', { className: 'summary--low temp' }, low),
           h('div', { className: 'summary--temp-bar', style: `--bar-width: ${width}rem` }),
           h('div', { className: 'summary--high temp' }, high),
           h('div', { className: 'summary--offset', style: `--bar-width: ${offset}rem` }),
          ),
        ),
      ),
      h('div', { className: 'weather--row' },
        h('p', { className: 'weather--row_description' }, `${period.detailedForecast}`),
        h('div', { className: 'weather--row_graphics' },
          ...hours.map((hour) => {
            return HourRow({
              hour,
              high
            });
          })
        )
      )
    );

    list.appendChild(group);
  });

  return list;
}

export function HourRow({ hour, high }) {
  const graphics = h('div',
    { className: 'icon-group' },
    h(
      'div',
      { className: 'icon', data: { short: hour.shortForecast } },
      getIcon(hour.shortForecast),
    )
  );

  const start = new Date(Date.parse(hour.startTime));

  const formatted = new Intl.DateTimeFormat(
    navigator.language,
    { hour: 'numeric', hour12: true },
  ).format(start)

  const wind = parseInt(hour.windSpeed, 0);
  if (wind && wind > 15) {
    graphics.appendChild(h('div', { className: 'windspeed', style: `--wind-speed: ${wind * Math.random() * 10}deg` }, '🍃'));
  }

  if (Number.isInteger((parseInt(formatted, 0) / 2))) {
    return h('div', {
      className: 'weather--row_cell',
      style: `--temp-height: ${(high - hour.temperature) * 0.3}rem`,
    }, h('div', {
      className: 'current-temp temp',
    }, `${hour.temperature}`),
      graphics,
      h('div', { className: 'time' }, formatted)
    );
  }
}

async function startNotifications() {
  Notification.requestPermission((res) => {
    if (res === "granted") {
      navigator.serviceWorker.ready.then((registration) => {
        localStorage.setItem('notifications', Date.now().toString());
        registration.showNotification("Subscribed!", {
          body: "Welcome to realtime alerts!",
          icon: "/apple-touch-icon.png",
          vibrate: [200, 100, 200, 100, 200, 100, 200],
          // tag: "vibration-sample",
        })
      });
    } else {
      console.error('notifications were not granted');
    }
  });
}

function GeneratedAt(forecast: IForecast) {
  const {
    periods,
  } = forecast.hourly;

  const {
    temperature,
    shortForecast
  } = periods[0];

  return h('div', {className: 'halign'},
    h('h3', {className: 'valign'},
      h('span', {className: 'temp'}, `${temperature}`),
      h('span', {}, ` ${getIcon(shortForecast)} ${shortForecast.toLowerCase()}`),
    ),
  );
}
