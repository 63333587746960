export function updateAtFormat(date: string) {
  try {
    const time = new Date(Date.parse(date))
    return new Intl.DateTimeFormat(
      navigator.language,
      { month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' },
    ).format(time)
  } catch (e) {
    console.log(e);
  }
}
