const HOST = 'https://wthr.cloud/api';
// const HOST = 'http://localhost:9292';

export let RETRY_DELAY = 1000; // ms

export function setRetryDelay(delay: number) {
  RETRY_DELAY = delay;
}

export async function get<T>(route: string, retry=0, fetch=window.fetch) {
  while (retry >= 0) {
    console.log(`[api] fetch ${route}`);

    try {
      const resp = await fetch(route, {
        headers: {
          Accept: 'application/ld+json',
        }

      });

      if (!resp.ok) {
        throw new Error(`request ${route} failed with ${resp.status}`)
      }

      return resp.json() as T;
    } catch(e) {
      if (retry <= 0) {
        console.log(`[api] retries exhausted -${e} - ${route}`);
        return Promise.reject(e);
      }

      await new Promise(r => setTimeout(r, RETRY_DELAY));

      console.log(`[api] retry -${e} - ${route}`);
    } finally {
      retry--;
    }
  }

  return Promise.reject();
}

interface IPosition {
  coords: {
    latitude: number,
    longitude: number
  }
}

interface IPointsResponse {
  relativeLocation: string
  gridId: string
  radarStation: string
  forecast: string
  forecastHourly: string
}

export interface IAlert {
  id: string
  headline: string
  expires: string
  description: string
  severity: 'Extreme'| 'Severe' | 'Moderate' | 'Minor' | 'Unknown'
}

export interface IAlertsResponse {
  "@graph": IAlert[]
}

interface ICurrentResponse {
  generatedAt: string;
  periods: any[];
}

interface IHourlyResponse {
  periods: any[];
}

export interface IForecast {
  relativeLocation: string
  gridId: string
  radarStation: string
  current: ICurrentResponse
  hourly: IHourlyResponse
  alerts: IAlertsResponse
}

export async function fetchForecast(pos: IPosition): Promise<IForecast> {
  if (!pos) {
    throw new Error(`missing position`);
  }

  const { latitude, longitude } = pos.coords;

  const resp = await get<IPointsResponse>(`${HOST}/forecast?latitude=${latitude}&longitude=${longitude}`, 1);
  return {
    relativeLocation: resp.relativeLocation.properties,
    gridId: resp.gridId,
    radarStation: resp.radarStation,
    current: resp.current.properties,
    hourly: resp.hourly.properties,
    alerts: resp.alerts,
  }
}
