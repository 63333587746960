import { h } from './h';
import { updateAtFormat } from './time';
import { IAlertsResponse } from './forecast';
import { params } from './params';

const SEVERE = ['Extreme', 'Severe'];

export function dismiss(id: string) {
  localStorage.setItem(
    `dismiss-${id}`,
    Date.now().toString()
  );
}

export function ignored(id: string) {
  if (params().dismissed) {
    return true;
  }

  return localStorage.getItem(`dismiss-${id}`) === null;
}

export function AlertView(data) {
  const alerts: IAlertsResponse = data.alerts;
  const filtered = alerts['features'].filter((f) => (
    ignored(f.properties.id)
  ));

  const list = filtered.map((f) => {
    const expiresAt = updateAtFormat(f.properties.expires);
    const level = SEVERE.includes(f.properties.severity) ? 'severe': 'warn'

    const dismissBtn = h('a', {
      href: "#dismiss",
      onClick(el) {
        dismiss(f.properties.id)
        const parent = el.target?.parentElement?.parentElement;
        if (parent) {
          parent.hidden = true;
        }
      }
    }, 'dismiss');

    return h('details', { className: `alert ${level}`, open: true },
      h('summary', {}, h('h4', {}, f.properties.headline)),
      h('p', {}, f.properties.description),
      h('div', { className: 'halign' },
        h('p', {}, `Expires: ${expiresAt}`),
        dismissBtn,
      )
    )
  });

  return h( 'section',
    { className: 'alerts' },
    ...list,
  );
}
