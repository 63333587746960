import { h } from './h';

export function FlashView(root) {
  return {
    displayAlert(msg: any, level = "warn") {
      root.appendChild(
        h('section', {className: `${level} alert`},
          h('h4', {}, 'Something wen\'t wrong, please refresh'),
          h('b', {}, String(msg)),
          h('pre', {}, String(msg.stack)),
        ),
      );
    }
  }
}
