export const icons = {
  'blizzard': '🏔',
  'clear': '☀️',
  'cloud': '☁️',
  'cold': '🧊',
  'dust': '🐪',
  'frost': '🧊',
  'fog': '🌫',
  'haze': '😕',
  'hot': '🌶',
  'ice': '🧊',
  'rain': '☔',
  'sand': '🐫',
  'showers': '☔',
  'sleet': '🌨',
  'smoke': '🚬',
  'snow': '🌨',
  'sunny': '🌞',
  'thunderstorms': '⛈️',
  'windy': '🌬',
}


export function getIcon(forecast) {
  let match;
  Object.keys(icons).forEach((icon) => {
    const matcher = new RegExp(icon, 'i');
    if (matcher.test(forecast)) {
      match = icons[icon];
      return;
    }
  });

  return match;
}
