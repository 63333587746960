interface IOpts extends Partial<HTMLElement> {
  className?: string
  data?: Record<string,string>
  open?: boolean
  href?: string
  disabled?: boolean
  onClick?: (e: Event) => void;
}

export function html(elements: TemplateStringsArray, opts?: IOpts) {
  let [name, ...children] = elements;
  if (!opts) {
    [name, ...children] = name.trim().split(' ');
  }

  return h(name.trim(), opts, ...children.map((c) => c.trim()));
}

export function h(name: string, opts: IOpts = {}, ...children: (string[] | undefined | HTMLElement[])): HTMLElement {
  const { dataset, ...attrs } = opts;
  const el = document.createElement(name);

  Object.keys(attrs).forEach((key) => {
    switch (key) {
      case 'className':
        el.setAttribute('class', attrs[key]);
        break;
      case 'data':
        Object.keys(opts.data).forEach((key) => {
          el.dataset[key] = opts.data[key];
        });
        break;
      case 'onClick':
        el.addEventListener('click', attrs[key])
        break;
      default:
        if (attrs[key]) {
          el.setAttribute(key, attrs[key]);
        }
    }
  });

  if (children.length === 0) {
    return el;
  }

  children.forEach((child: string | HTMLElement) => {
    if (typeof child === 'undefined') { return; }
    if (child.name === 'TypeError') { return; }

    if (typeof child === 'object') {
      el.appendChild(child);
    } else {
      el.appendChild(document.createTextNode(child));
    }
  });

  return el;
}
